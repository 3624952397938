import gql from "graphql-tag";
import { modules } from '~/graphql/subqueries/modules'
import { seo } from '~/graphql/subqueries/seo'

export const pageQuery = gql`
  query Page($publicationState: PublicationState!, $slug: String!) {
    pages(filters:{slug: {eq: $slug}}, publicationState: $publicationState) {
      data {
      id
        attributes {
          title
          displaytitle
          hero {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          info
          description
          ${modules}
          ${seo}
        }
      }
    }
  }
`